import { graphql, PageProps } from 'gatsby';
import { FC, useEffect, useState } from 'react';

import { SmartScript } from '../components/appsflyer-smart-script';
import RegisterLayout from '../components/registration/layouts/register-layout';
import DownloadApp from '../components/registration/steps/download-app';
import Seo from '../components/seo/seo';

import { Layout } from '../layouts';
import { getConsentState, StorageFlags } from '../lib/consent';
import { isBrowser } from '../lib/helpers';
import { handleSessionStorage, useReferralLink } from '../lib/referrals';
import { getParams } from '../lib/urls';
import { ContentfulNavigation } from '../modules/navigation/navigation.contentful';

const PaidLandingPage: FC<PageProps<Queries.PaidLandingPageQuery>> = ({
  data: { contentfulPaidLandingPage },
  location,
}) => {
  const { c: campaign, daveTag } = getParams(location?.search);
  const [storedCampaign, setStoredCampaign] = useState('');
  const [storedDaveTag, setStoredDaveTag] = useState('');
  const { seoMetaData, qrCode, downloadHeadline, showGlobalHeader, showRegistrationButton } =
    contentfulPaidLandingPage ?? {};

  const {
    consentState: { [StorageFlags.TARGETING_ADVERTISING]: adStorageValue },
  } = getConsentState();
  const isAdStorageEnabled = adStorageValue === 'granted';

  useReferralLink({ campaign, daveTag });

  useEffect(() => {
    if (isBrowser) {
      handleSessionStorage({ campaign, daveTag, setStoredCampaign, setStoredDaveTag });
    }
  }, [campaign, daveTag]);

  return (
    <main>
      <SmartScript isAdStorageEnabled={isAdStorageEnabled} />
      <Seo
        title={seoMetaData?.title ?? 'Dave - Banking for Humans'}
        description={seoMetaData?.descriptionText ?? ''}
        meta={
          seoMetaData?.hideFromSearchEngines
            ? [
                {
                  name: 'robots',
                  content: 'noindex',
                },
              ]
            : []
        }
        pageName={seoMetaData?.pageName ?? 'download'}
      />
      {showGlobalHeader && (
        <ContentfulNavigation showRegistrationButton={showRegistrationButton ?? undefined} />
      )}
      <Layout>
        <RegisterLayout data={contentfulPaidLandingPage} showMobileProgressBar={false}>
          <DownloadApp
            qrCode={{
              filename: qrCode?.filename ?? '',
              url: qrCode?.url ?? '',
            }}
            isNewUser
            campaign={storedCampaign}
            daveTag={storedDaveTag}
            headlineTitle={downloadHeadline ?? undefined}
            defaultHeadlineTitle="Get up to $500 in 5 minutes or less"
          />
        </RegisterLayout>
      </Layout>
    </main>
  );
};

export const query = graphql`
  query PaidLandingPage($slug: String) {
    contentfulPaidLandingPage(slug: { eq: $slug }) {
      seoMetaData {
        title
        descriptionText
        hideFromSearchEngines
        pageName
      }
      showGlobalHeader
      showRegistrationButton
      qrCode {
        filename
        url
      }
      slug
      termsLinkButton {
        path
        text
      }
      termsModal {
        ...RichTextModule
      }
      downloadHeadline
      valuesPanelTitle
      valuesPanelItems {
        itemHeading
        itemSubheading
        itemBulletImage {
          filename
          url
          description
        }
      }
    }
  }
`;

export default PaidLandingPage;
