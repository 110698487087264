import { PageProps, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { FC } from 'react';

import EVENTS from '../components/app-store-buttons/events';
import { useAppStoreLink } from '../components/app-store-buttons/helpers';
import { Button } from '../components/button';
import { ContentfulFooterBlock } from '../components/footer-block/footer-block.contentful';
import Seo from '../components/seo/seo';
import { BodyLg, H3 } from '../components/typography';
import { Layout } from '../layouts';
import { trackEvent } from '../lib/analytics';
import { ContentfulNavigation } from '../modules/navigation/navigation.contentful';

import { Footer, Main } from './{ContentfulMainTemplate.slug}';

const NotFoundPage: FC<PageProps<Queries.NotFoundPageQuery>> = ({
  data: { contentfulMainTemplate },
}) => {
  const { footer } = contentfulMainTemplate ?? {};
  const { APP_STORE_ANDROID, APP_STORE_IOS } = useAppStoreLink();
  return (
    <Layout>
      <Seo title="Dave - Banking for Humans (404)" pageName="404" />
      <ContentfulNavigation />
      <Main isForm={false}>
        <div className="text-center">
          <H3 as="h1" className="mb-4">
            Are you lost?
          </H3>
          <BodyLg className="mb-8">Let's get you back to shore.</BodyLg>
          <div className="mb-8">
            <Button
              icon="ios"
              onClick={() => {
                trackEvent(EVENTS.DOWNLOAD_DAVE_SELECTED, { all: { href: APP_STORE_IOS } });
              }}
              size="small"
              variant="download"
              className="mb-4 w-full md:mx-2 md:w-auto"
              to={APP_STORE_IOS}
            />
            <Button
              icon="android"
              onClick={() => {
                trackEvent(EVENTS.DOWNLOAD_DAVE_SELECTED, { all: { href: APP_STORE_ANDROID } });
              }}
              size="small"
              variant="download"
              className="mb-4 w-full md:mx-2 md:w-auto"
              to={APP_STORE_ANDROID}
            />
          </div>
          <StaticImage src="../assets/images/404/404.svg" alt="icon" />
        </div>
      </Main>
      <Footer>
        <ContentfulFooterBlock footer={footer as Queries.FooterModuleFragment['footer']} />
      </Footer>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundPage {
    contentfulMainTemplate(slug: { eq: "/" }) {
      pageNavbar {
        to
        text
      }
      ...FooterModule
    }
  }
`;
