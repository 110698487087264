/* eslint-disable @typescript-eslint/ban-ts-comment */
import { isBrowser } from './helpers';

export const daveConsentPrefCookie = 'daveConsentChoices';
export const StorageFlags = {
  PERFORMANCE_ANALYTICS: 'analytics_storage',
  FUNCTIONAL: 'personalization_storage',
  TARGETING_ADVERTISING: 'ad_storage',
};
export type StorageFlagKeys = (typeof StorageFlags)[keyof typeof StorageFlags];
export type StorageFlagValues = 'granted' | 'denied';

export const getConsentState = () => {
  if (isBrowser) {
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith(`${daveConsentPrefCookie}=`))
      ?.split('=')[1];

    if (cookieValue) {
      return {
        isConsentSaved: true,
        consentState: JSON.parse(cookieValue),
      };
    }
  }
  return {
    isConsentSaved: false,
    consentState: {
      [StorageFlags.PERFORMANCE_ANALYTICS]: 'denied',
      [StorageFlags.FUNCTIONAL]: 'denied',
      [StorageFlags.TARGETING_ADVERTISING]: 'denied',
    },
  };
};

export const isCookieEnabled = (value: StorageFlagValues) => value === 'granted';

export const setConsentState = (consentState: {
  [key in StorageFlagKeys]: StorageFlagValues;
}) => {
  const consentPreferences = {
    [StorageFlags.PERFORMANCE_ANALYTICS]: consentState[StorageFlags.PERFORMANCE_ANALYTICS],
    [StorageFlags.FUNCTIONAL]: consentState[StorageFlags.FUNCTIONAL],
    [StorageFlags.TARGETING_ADVERTISING]: consentState[StorageFlags.TARGETING_ADVERTISING],
    ad_user_data: consentState[StorageFlags.TARGETING_ADVERTISING],
    ad_personalization: consentState[StorageFlags.TARGETING_ADVERTISING],
    functionality_storage: 'granted',
    security_storage: 'granted',
  };
  const consentPreferencesString = JSON.stringify(consentPreferences);

  let expiration = null;
  // If personalization/function cookies are enabled, set an expiration of one year
  // If not, the cookie will expire with the session
  if (isCookieEnabled(consentState[StorageFlags.FUNCTIONAL])) {
    expiration = 31536000; // One year
  }

  if (isBrowser) {
    if (isCookieEnabled(consentState[StorageFlags.TARGETING_ADVERTISING])) {
      // AF is an AppsFlyer function added in public/appsflyer-sdk.js
      // Typescript error is from AF not existing in Window type
      // https://support.appsflyer.com/hc/en-us/articles/360001610038-PBA-Web-SDK-integration-guide#recording-events
      // @ts-ignore
      window.AF_SDK.PLUGINS.PBA.enableMeasurement();
    }
    document.cookie = `${daveConsentPrefCookie}=${consentPreferencesString};${
      expiration ? `max-age=${expiration}` : ''
    };path=/`;
  }
};
